import styled from "styled-components";

export const Button = styled.button`
  border-radius: 5px;
  background: ${({ primary }) => (primary ? "#6e31b2" : "#9e46ff")};
  white-space: nowrap;
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-family: "Montserrat", sans-serif;
  font-weight: 100 !important;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  &.active {
      background-color: #3a3a3a;
  }

`;